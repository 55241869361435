import React from 'react'
import './About.scss'
import SmallWrapper from '../../Components/SmallWrapper'
import Footer from '../../Components/Footer'
import sample1 from '../../images/about-main-1.jpeg'
import sample2 from '../../images/about-main-2.jpeg'
import ScrollProxy from '../../Components/ScrollProxy'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Header from '../../Components/Header';
import { AboutContent } from '../../Components/Data';


function About() {


    return (
        
        <div id="about-head">
        <ScrollProxy/>
            <Header color="#111" colorlogo="#111" />
            
            <div id="about">
                <SmallWrapper para1={AboutContent.paraOne} para2={AboutContent.paraTwo} line1={AboutContent.line1} line2={AboutContent.line2}/>

                <section className="about-carousel">
                    <div className="container-fluid">
                        <div className="about-carousel__inner">
                            <Carousel showArrows={false} dynamicHeight={true} swipeable={true} emulateTouch={true} showIndicators={false} autoPlay={false}>
                                <div className="carousel-inner">
                                    <img src={sample1} alt="" />
                                </div>

                                <div className="carousel-inner">
                                    <img src={sample2} alt="" />
                                </div>
                            </Carousel>
                        </div>

                        
                    </div>
                </section>

                <section className="strengths">
                    <div className="about__bg">POWER</div>
                    <div className="small-wrapper">
                        <div className="strengths-head">
                            <h3>
                                Our Strengths
                            </h3>
                            <p>Combining elements that have never been combined - that is our forte and passion.</p>
                        </div>

                        <div className="strengths-main">
                            <div className="row">
                                <div className="col-12 col-md-4 col-xl-4 col-lg-4">
                                    <div className="card">
                                        <div className="card-head">
                                            <img src="https://yodezeen.com/wp-content/uploads/2017/11/graphic-design2.png" style={{width: '46px'}} alt="" className="card__img"/>
                                            <div className="card-head__text">
                                                <p>Fine</p>
                                                <p>Execution</p>
                                            </div>
                                        </div>
                
                                        <div className="card-body">
                                            <p>
                                                Fueled by the desire to be unique, we execute, design and deliver technologically advanced, user friendly and environmentally sustainable interiors that are ready for the future. 
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 col-xl-4 col-lg-4">
                                    <div className="card">
                                        <div className="card-head">
                                            <img src="https://yodezeen.com/wp-content/uploads/2017/11/people-1.svg" alt="" className="card__img"/>
                                            <div className="card-head__text">
                                                <p>The Best </p>
                                                <p>Fit and Finish</p>
                                            </div>
                                        </div>
                
                                        <div className="card-body">
                                            <p>
                                                With over 355 projects, we have perfeced the art of delivering the perect fit and classy finish to the interiors of your next project. 
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4 col-xl-4 col-lg-4">
                                    <div className="card">
                                        <div className="card-head">
                                            <img src="https://yodezeen.com/wp-content/uploads/2017/11/pencil-1.svg" alt="" className="card__img"/>
                                            <div className="card-head__text">
                                                <p>355+ </p>
                                                <p>Finished projects</p>
                                            </div>
                                        </div>
                
                                        <div className="card-body">
                                            <p>
                                            We are constantly growing, learning, and improving and our partners are steadily increasing. 355+ projects is a sizable number, but we always strive to be better, more relevant, more determined, and more successful than yesterday. We are not going to stop.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
      
    )
}

export default About
